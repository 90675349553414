import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Wrapper, Module, ModuleBody, Link } from "@wfp/ui";
import TableMain from "./table";
import { useNavigate, useLocation } from "react-router-dom";
import { loadResults as loadResultsFn } from "../reducers/resultsReducer";
import { resultsParams } from "../utils/params";
import { loadFile as loadFileFn } from "../reducers/fileReducer";
import { FILTER_FIELDS } from "./search";
import isEmpty from "lodash/isEmpty";

export const DEFAULT_PAGE_SIZE = 10;

const texts = {
  info: "**For reports prior to 2018, please contact",
  info_2: "*To access 2018 web-based Annual Country reports visit this",
  mail_1: "sprsdonorqueries@wfp.org",
  noMatches:
    "No results found. Try a different combination of search criteria.​",
  guide: "Download the guide on how to read the report​.",
};

const Results = ({ loadResults, data, loading, totalItems, loadFile }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let queryParams = queryString.parse(location.search, {
      arrayFormat: "comma",
    });

    if (!queryParams?.["page_size"]) {
      queryParams = R.assoc("page_size", DEFAULT_PAGE_SIZE, queryParams);
    }

    if (!queryParams?.["page"]) {
      queryParams = R.assoc("page", 0, queryParams);
    }

    if (!queryParams?.["orderBy"]) {
      queryParams = R.assoc(
        "orderBy",
        [FILTER_FIELDS.COUNTRY, `-${FILTER_FIELDS.YEAR}`],
        queryParams
      );
    }
    navigate(
      `/search?${queryString.stringify(queryParams, { arrayFormat: "comma" })}`
    );
  }, [location.search, navigate]);

  function updateUrlParams(key, value) {
    let queryParams = queryString.parse(location.search, {
      arrayFormat: "comma",
    });

    if (key === "page_size" && value !== Number(queryParams[key])) {
      queryParams = R.assoc("page_size", value, queryParams);
      queryParams = R.assoc("page", 0, queryParams);

      loadResults(
        resultsParams(
          queryString.stringify(queryParams, { arrayFormat: "comma" })
        )
      );
      navigate(
        `/search?${queryString.stringify(queryParams, {
          arrayFormat: "comma",
        })}`
      );
    } else if (key === "page" && value !== Number(queryParams[key])) {
      queryParams = R.assoc("page", value, queryParams);

      loadResults(
        resultsParams(
          queryString.stringify(queryParams, { arrayFormat: "comma" })
        )
      );
      navigate(
        `/search?${queryString.stringify(queryParams, {
          arrayFormat: "comma",
        })}`
      );
    } else if (key === "orderBy") {
      if (!isEmpty(value)) {
        const orderByVal = value.map((v) => (v.desc ? `-${v.id}` : v.id));
        queryParams = R.assoc("orderBy", orderByVal, queryParams);
      } else {
        queryParams = R.dissoc("orderBy", queryParams);
      }

      loadResults(
        resultsParams(
          queryString.stringify(queryParams, { arrayFormat: "comma" })
        )
      );
      navigate(
        `/search?${queryString.stringify(queryParams, {
          arrayFormat: "comma",
        })}`
      );
    }
  }

  const NoResults = () => {
    return (
      <Module className="infoModule">
        <ModuleBody className="infoCenterBodyModule">
          <div className="info">{texts.noMatches}</div>
        </ModuleBody>
      </Module>
    );
  };

  return (
    <div className="content">
      <Wrapper background="white" pageWidth="lg">
        {!data && !loading ? null : data && data.length === 0 && !loading ? (
          <NoResults />
        ) : (
          <Module className="infoModule" light>
            <TableMain
              className="-striped"
              data={data || []}
              loading={loading}
              totalItems={totalItems || 0}
              updateUrlParams={updateUrlParams}
              columns={[
                {
                  headerStyle: { background: "white" },
                  Header: "",
                  width: 25,
                  sortable: false,
                  accessor: "project_status",
                  disableSortBy: true,
                },
                {
                  headerStyle: { background: "white" },
                  Header: "Project",
                  width: 50,
                  accessor: "project_number",
                  Cell: (row) => (
                    <div>
                      <Link
                        onClick={() =>
                          loadFile(row.row.original.report_filename)
                        }
                      >
                        {row.value}
                      </Link>
                    </div>
                  ),
                },
                {
                  headerStyle: { background: "white" },
                  Header: "Recipient",
                  maxWidth: 150,
                  accessor: "country_name",
                },
                {
                  headerStyle: { background: "white" },
                  Header: "Category",
                  width: 60,
                  accessor: "project_category",
                },
                {
                  headerStyle: { background: "white" },
                  Header: "Title",
                  minWidth: 250,
                  accessor: "project_title",
                },
                {
                  headerStyle: { background: "white" },
                  Header: "Year",
                  width: 40,
                  accessor: "spr_year",
                },
              ]}
            />
          </Module>
        )}
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: R.pathOr(null, ["results", "data"], state),
    loading: R.pathOr(false, ["results", "loading"], state),
    totalItems: R.pathOr(
      false,
      ["results", "pagination", "totalResults"],
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadResults: (params) => dispatch(loadResultsFn(params)),
  loadFile: (fileName) => dispatch(loadFileFn(fileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
